<template>
  <div>
    <div class="resume hidden-xs-only">
      <div class="box">
        <div class="container header">
          <ul class="top flex row-center">
            <li
              v-for="(item, index) in tabs"
              :key="index"
              :class="{ active: current == index }"
              @click="switchTab(index, 1)"
            >
              {{ item.title }}
            </li>
          </ul>
          <!-- <div v-if="!show" class="out-login" @click="login">[未登录]</div>
          <div v-if="show" class="out-login" @click="outLogin">[退出登录]</div> -->
        </div>
      </div>

      <div class="container">
        <ul class="list">
          <li class="item" v-for="(item, index) in list" :key="index">
            <div class="flex row-between">
              <p>
                <span v-if="item.job_position" class="title">
                  {{ item.job_position.name }}[{{
                    item.recruitment.city
                  }}]</span
                >
                <span class="type">{{ types[item.recruitment.type_id] }}</span>
              </p>
              <span class="status">{{ states[item.state] }}</span>
            </div>
            <div class="flex row-between" style="margin-top: 10px">
              <p class="subtitle">
                月薪 {{ item.recruitment.salary.split(",")[0] }}-
                {{ item.recruitment.salary.split(",")[1] }}k |
                {{ item.recruitment.duration }}年 |
                {{ educations[item.recruitment.education] }} |
                {{ item.recruitment.recruits_num }}人
              </p>
              <p class="subtitle">{{ item.created_at | formatDate }}</p>
            </div>
          </li>
        </ul>
      </div>
      <el-dialog
        title="清微智能网站"
        center
        :visible.sync="loginVisible"
        width="410px"
        @close="handleClose('loginForm')"
      >
        <el-form
          ref="loginForm"
          :model="loginForm"
          size="mini"
          label-width="80px"
          :rules="rules"
        >
          <el-form-item label="手机号：" prop="tel" :error="errorTel">
            <el-input
              v-model="loginForm.tel"
              placeholder="请输入手机号"
              autocomplete="off"
            />
          </el-form-item>
          <el-form-item label="验证码：" prop="code">
            <div class="flex">
              <el-input
                v-model="loginForm.code"
                autocomplete="off"
                placeholder="请输入验证码"
              />
              <el-button
                class="m-l-24"
                size="mini"
                type="danger"
                @click="getCodeHandle"
                >{{ get_code_text }}
              </el-button>
            </div>
          </el-form-item>
        </el-form>
        <el-button
          type="danger"
          style="width: 100%"
          @click="handLogin('loginForm', 1)"
          >登录/注册</el-button
        >
        <el-checkbox class="m-t-24" v-model="checked" text-fill="#666666"
          >登录/注册即代表您阅'读并同意
        </el-checkbox>
        <el-link type="primary" @click="goInest">《用户协议》 </el-link>
        <div class="login">
          <p>其他登录方式</p>
          <img src="@/assets/images/weixin.png" alt="" @click="wxlogin" />
        </div>
      </el-dialog>
    </div>
    <div class="hidden-sm-and-up">
      <van-nav-bar
        title="我的投递"
        left-arrow
        fixed
        placeholder
        @click-left="back"
      >
        <template #right>
          <span v-if="!show" class="color" @click="show1 = true">[未登录]</span>
          <span class="color" v-else @click="out">[退出登录]</span>
        </template>
      </van-nav-bar>
      <van-tabs
        class="m-t-24"
        v-model="current"
        type="card"
        @change="
          (res) => {
            switchTab(res, 2);
          }
        "
      >
        <van-tab
          v-for="item in tabs"
          :key="item.value"
          :title="item.title"
          :name="item.value"
        />
      </van-tabs>
      <van-list
        v-model="loading"
        :immediate-check="false"
        :finished="finished"
        finished-text="没有更多了"
        @load="getMyResume"
      >
        <van-cell v-for="item in list" :key="item.id">
          <template #title>
            <div class="title-4">
              <span v-if="item.job_position">{{ item.job_position.name }}</span>
              <span>【{{ item.recruitment.city }}】</span>
            </div>
            <div class="subtitle">
              <!-- 月薪 {{ item.recruitment.salary.split(",")[0] }}-
              {{ item.recruitment.salary.split(",")[1] }}k | -->
              {{ item.recruitment.duration }} |
              {{ educations[item.recruitment.education] }}
              <!-- |
              {{ item.recruitment.recruits_num }}人 -->
            </div>
            <div class="flex row-center row-between">
              <span class="subtitle">{{ item.created_at | formatDate }}</span>
              <span class="status">{{ states[item.state] }}</span>
            </div>
          </template>
        </van-cell>
      </van-list>
      <van-dialog
        v-model="show1"
        title="清微智能网站"
        width="360"
        :showConfirmButton="false"
        :showCancelButton="false"
      >
        <div class="cross" @click="handleClose('loginForm')">
          <van-icon name="cross" />
        </div>
        <div class="m-15">
          <el-form
            ref="loginForm"
            :model="loginForm"
            size="mini"
            label-width="80px"
            :rules="rules"
          >
            <el-form-item label="手机号：" prop="tel" :error="errorTel">
              <el-input
                v-model="loginForm.tel"
                placeholder="请输入手机号"
                autocomplete="off"
              />
            </el-form-item>
            <el-form-item label="验证码：" prop="code">
              <div class="flex">
                <el-input
                  v-model="loginForm.code"
                  autocomplete="off"
                  placeholder="请输入验证码"
                />
                <el-button
                  class="m-l-24"
                  size="mini"
                  type="danger"
                  @click="getCodeHandle"
                  >{{ get_code_text }}
                </el-button>
              </div>
            </el-form-item>
          </el-form>
          <van-button type="danger" block @click="handLogin('loginForm', 2)"
            >登录/注册
          </van-button>
          <el-checkbox class="m-t-24" v-model="checked" text-fill="#666666"
            >登录/注册即代表您阅读并同意
          </el-checkbox>
          <el-link type="primary" href="https://www.sinmore.com.cn/inest/9"
            >《用户协议》
          </el-link>
          <div class="login">
            <p>其他登录方式</p>
            <img src="@/assets/images/weixin.png" alt="" @click="h5login" />
          </div>
        </div>
      </van-dialog>
    </div>
  </div>
</template>

<script>
import { sms, login, myResume } from "@/assets/js/api";
import { Toast } from "vant";
export default {
  data() {
    return {
      show1: false,
      rules: {
        tel: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      errorTel: "",
      get_code_text: "获取验证码",
      loginVisible: false,
      checked: true,
      loginForm: {
        tel: "",
        code: "",
      },
      types: {
        0: "校招",
        1: "社招",
      },
      states: {
        0: "初筛",
        1: "面试邀请",
        2: "已通过初试",
        3: "已通过复试",
        4: "已录用",
        5: "爽约面试",
        6: "爽约入职",
        7: "淘汰",
        8: "人才库",
      },
      educations: {
        0: "全部",
        1: "本科及以上",
        2: "硕士及以",
        3: "博士",
      },
      current: 0,
      tabs: [
        {
          title: "本人投递简历",
          value: 0,
        },
        {
          title: "内推简历",
          value: 1,
        },
      ],
      list: [],
      show: false,
      loading: false,
      finished: false,
      qrCode: "",
    };
  },
  created() {
    let flag =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    if (flag && !this.$cookies.get("token")) {
      this.show1 = true;
    }
    if (this.$cookies.get("token")) {
      this.getMyResume();
      this.show = true;
    }
  },
  filters: {
    formatDate(time) {
      //时间戳转日期
      let date = new Date(parseInt(time) * 1000);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
      // return y + '-' + MM + '-' + d;
    },
  },

  methods: {
    goInest() {
      window.open(`https://www.sinmore.com.cn/inest/9`, "_blank");
    },
    h5login() {
      sessionStorage.setItem("loginMethod", "h5");
      let local = encodeURIComponent(window.location.href); //获取当前url
      let appid = "wx95c437c12b79939e";
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${local}&response_type=code&scope=snsapi_userinfo&state=wecedirect#wechat_redirect`;
    },
    wxlogin() {
      sessionStorage.setItem("loginMethod", "pc");
      let appId = "wxb77761cc82a3140e";
      let local = encodeURIComponent(window.location.href); //获取当前url
      window.location.href = `https://open.weixin.qq.com/connect/qrconnect?appid=${appId}&redirect_uri=${local}&response_type=code&scope=snsapi_login&state=state#wechat_redirect`;
      // window.open(
      //   `https://open.weixin.qq.com/connect/qrconnect?appid=${appId}&redirect_uri=${redirectUri}&response_type=code&scope=snsapi_login&state=${this.id}#wechat_redirect`,
      //   "_blank"
      // );
    },
    back() {
      this.$router.go(-1);
    },
    login() {
      this.loginVisible = true;
    },
    out() {
      this.$dialog
        .alert({
          title: "退出登录",
          showCancelButton: true,
          message: "是否确定退出登录吗?",
        })
        .then(() => {
          this.$cookies.remove("token");
          this.show = false;
          this.list = [];
        });
    },
    outLogin() {
      this.$confirm("是否确定退出登录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$cookies.remove("token");
        this.show1 = false;
        this.list = [];
      });
    },
    // 获取验证码
    getCodeHandle() {
      if (!this.loginForm.tel) {
        this.errorTel = "请输入手机号";
        return;
      }
      if (!/^1[3456789]\d{9}$/.test(this.loginForm.tel)) {
        this.errorTel = "手机号格式错误";
        return;
      }
      this.errorTel = "";
      let _this = this;
      if (this.get_code_text !== "获取验证码") return false;
      sms({
        tel: this.loginForm.tel,
      }).then((res) => {
        let num = 60;
        this.get_code_text = num + "s后重试";
        this.timer = setInterval(() => {
          num = num - 1;
          if (num <= 0) {
            clearInterval(_this.timer);
            _this.get_code_text = "获取验证码";
          } else {
            _this.get_code_text = num + "s后重试";
          }
        }, 1000);
      });
    },
    handLogin(formName, action) {
      this.$refs[formName].validate((valid) => {
        if (!this.loginForm.tel) {
          this.errorTel = "请输入手机号";
          return;
        }
        if (!/^1[3456789]\d{9}$/.test(this.loginForm.tel)) {
          this.errorTel = "手机号格式错误";
          return;
        }
        if (valid) {
          login(this.loginForm).then((res) => {
            if (this.action == 1) {
              this.$message.success("登录成功");
            } else {
              Toast("登录成功");
            }
            this.$cookies.set("token", `Bearer ${res.token}`, 60 * 60 * 24);
            this.handleClose(formName);
            this.getMyResume();
            this.show = true;
          });
        }
      });
    },
    handleClose(formName) {
      this.$refs[formName].clearValidate(["tel"]);
      this.resetForm(formName);
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.loginForm.tel = "";
      this.loginVisible = false;
      this.show1 = false;
    },
    switchTab(index, action) {
      this.current = index;
      if (!this.$cookies.get("token")) {
        if (action == 1) {
          this.loginVisible = true;
        } else {
          this.show1 = true;
        }
      } else {
        this.list = [];
        this.loading = false;
        this.finished = false;
        if (action == 1) {
          this.getMyResume();
        }
      }
    },
    getMyResume() {
      // const loading = this.$loading({
      //   lock: true,
      //   text: "Loading",
      //   spinner: "el-icon-loading",
      //   background: "rgba(0, 0, 0, 0.7)",
      // });
      console.log("111");
      myResume({
        is_recommend: this.tabs[this.current].value,
      }).then(
        (res) => {
          this.list = res.data;
          this.loading = false;
          this.finished = true;
          // loading.close();
        },
        (err) => {
          // loading.close();
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.title-4 {
  font-size: 14px;
  font-weight: bold;
}
.login {
  margin-top: 24px;
  color: #666;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > p {
    margin-bottom: 10px;
  }
  > img {
    cursor: pointer;
  }
}
.cross {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 20px;
  top: 20px;
  z-index: 999999;
  font-size: 20px;
}
/deep/ .van-nav-bar .van-icon {
  color: #fff;
  font-size: 20px;
}
/deep/ .van-nav-bar__title {
  color: #fff;
}
/deep/ .van-nav-bar {
  background-color: #cf322e;
}
/deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #666666;
}
/deep/ .el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #666666;
}
/deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  border-color: #d0322e;
  background-color: #d0322e;
}
.resume {
  .header {
    display: flex;
    justify-content: space-between;
    .out-login {
      padding-top: 90px;
      cursor: pointer;
    }
  }

  .list {
    margin-top: 30px;

    .item {
      padding: 20px;
      margin-bottom: 24px;
      border-radius: 2px;
      border: 1px solid #cccccc;

      .title {
        font-weight: bold;
        margin-right: 30px;
        font-size: 14px;
      }

      .type {
        padding: 2px 17px;
        color: #cf322e;
        border-radius: 2px;
        font-size: 12px;
        border: 1px solid #cf322e;
      }

      .subtitle {
        color: #666666;
        font-size: 12px;
      }

      .status {
        color: #cf322e;
        font-weight: bold;
        font-size: 12px;
      }
    }
  }

  .box {
    height: 140px;
    background: #f7f7f7;

    .top {
      padding-top: 70px;

      > li {
        cursor: pointer;
        font-size: 12px;
        width: 200px;
        height: 40px;
        margin-right: 40px;
        text-align: center;
        line-height: 40px;
        border-radius: 2px;
        background: #fafafa;
        border: 1px solid #e8e8e8;
      }

      .active {
        color: #cf322e;
        background: #fff8f8;
        border: 1px solid #cf322e;
      }
    }
  }
}
</style>